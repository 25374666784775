<template>
  <div class="home2">
    <div class="all">
      <div class="tit">{{ detail.title }}</div>
      <div class="dateTime">{{ detail.dateTime }}</div>
      <div class="content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { newinfo } from '@/api/news/index'
export default {
  name: '',
  components: {

  },
  // 定义属性
  data() {
    return {
      detail: {}
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async init() {
      if (this.$route.query.id) {
        const { data } = await newinfo(this.$route.query.id)
        this.detail = data
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.home2 {
  background: #fff;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: auto;
}

.all {
  width: 60%;
  margin: 0 auto;
  background: #FFFFFF;


  .tit {
    font-weight: 500;
    font-size: 20px;
    color: #1C74D2;
    line-height: 28px;
    text-align: center;
    font-style: normal;
  }

  .dateTime {
    padding: 12px 15px;
    background: #EBF5FF;
    font-weight: 400;
    font-size: 13px;
    color: #1C74D2;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content{
  white-space: pre-wrap;
}
</style>